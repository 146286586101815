import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resourcesToBackend from 'i18next-resources-to-backend';
i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./${language}/${namespace}.yml`)
        .then((resources) => {
          callback(null, resources.default);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .use(initReactI18next)
  .init({
    fallbackLng: 'zh-CN-Hans',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
