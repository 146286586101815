import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import logo from '../../commons/assets/logo.svg';
import { useAuth } from '../../commons/auth/auth.context';
import { useHttpClient } from '../../commons/rpc/http-client.context';

const OrganizationIcon = () => {
  const { t } = useTranslation('commons');
  const { currentOrganization } = useAuth();
  const axios = useHttpClient();
  const history = useHistory();
  const [iconSrc, setIconSrc] = useState(logo);
  useEffect(() => {
    if (currentOrganization) {
      if (currentOrganization?.logo) {
        axios
          .get(`files/view/${currentOrganization.logo}`, {
            responseType: 'blob',
            silent: true,
          })
          .then((res) => {
            const url = URL.createObjectURL(res.data);
            setIconSrc(url);
          })
          .catch(() => {
            setIconSrc(logo);
          });
      } else {
        setIconSrc(logo);
      }
    }
  }, [currentOrganization?.logo]);
  return (
    <Space style={{ marginRight: 10 }}>
      <img src={iconSrc} style={{ height: 26 }} />
      <span
        onClick={() => history.push('/')}
        style={{
          fontSize: 18,
          cursor: 'pointer',
        }}
      >
        {currentOrganization?.name || t('system.company')}
      </span>
    </Space>
  );
};

export default OrganizationIcon;
