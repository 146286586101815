import { generateRoutes } from '../utils/generate-routes';
import { lazy, useMemo } from 'react';
import { RouteConfig } from 'react-router-config';
import { usePages } from '../contexts/pages.context';
export const useRouteConfig = (): RouteConfig[] => {
  const pages = usePages();
  const routes = useMemo(
    () => [
      ...generateRoutes(pages.filter((p) => p.parents.length === 0)),
      {
        path: '*',
        component: lazy(() => import('../../layouts/404')),
      },
    ],
    [],
  );

  return routes;
};
