import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Space } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from '../../commons/auth/auth.context';

export const HeaderUserInfo: FC = () => {
  const { t } = useTranslation(['commons']);
  const { user, logout } = useAuth();
  const history = useHistory();
  const menu = (
    <Menu>
      <Menu.Item>
        <a
          onClick={(e) => {
            e.preventDefault();
            history.push('/personal/user-info');
          }}
        >
          {t('header.userDropdown.personalInfo')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          {t('header.userDropdown.logout')}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} arrow placement="bottomCenter">
      <a style={{ padding: '0 6px' }}>
        <Space>
          <UserOutlined />
          <span>{user?.nick}</span>
        </Space>
      </a>
    </Dropdown>
  );
};
