import { Divider, Menu, Space } from 'antd';
import { MenuItem } from '../../routes/interfaces/menu-item.interface';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailablePages } from '../../routes/contexts/available-pages.context';
import { generateMenu } from '../../routes/utils/generate-menu';
import styles from './default-header.module.less';
import { HeaderUserInfo } from './header-user-info';
import { MessageBox } from './message-box';
import { SwitchOrganization } from './switch-organization';
import { useHistory } from 'react-router';
import OrganizationIcon from './header-organization-icon';

export const DefaultHeader: FC = () => {
  return (
    <header className={styles.header}>
      <Space>
        <OrganizationIcon />
        <Nav />
      </Space>
      <div className={styles.right}>
        <Space>
          <MessageBox />
          <Divider type="vertical" />
          <SwitchOrganization />
          <Divider type="vertical" />
          <HeaderUserInfo />
        </Space>
      </div>
    </header>
  );
};

const Nav: FC = () => {
  const pages = useAvailablePages();
  const menus = useMemo(() => generateMenu(pages), [pages]);
  const { t } = useTranslation('pages');
  const history = useHistory();

  const handleMenuItemClick = useCallback(
    (meta: { keyPath: string[] }) => {
      const fullKey = meta.keyPath.reverse().join('.');
      const menu = pages.find((p) => p.fullKey === fullKey);
      if (!menu) {
        throw new Error('未找到路由');
      }
      history.push(menu.fullPath);
    },
    [pages],
  );

  const getMenuItem = (menus: MenuItem[]) => {
    return menus.map((item) => {
      const title = (
        <Space>
          {item.icon}
          <span>{t(item.title)}</span>
        </Space>
      );
      if (item.children?.length) {
        return (
          <Menu.SubMenu key={item.key} title={title}>
            {getMenuItem(item.children)}
          </Menu.SubMenu>
        );
      } else {
        return <Menu.Item key={item.key}>{title}</Menu.Item>;
      }
    });
  };

  const [key, setKey] = useState<string>();
  useEffect(() => {
    setKey(Math.random().toString());
  }, [menus]);

  const selectedKey = useMemo(() => {
    return [...menus]
      .reverse()
      .find((menu) => history.location.pathname.startsWith(menu.path))
      ?.key as string;
  }, [history.location.pathname]);
  return (
    <Menu
      mode="horizontal"
      onClick={(meta) => handleMenuItemClick(meta)}
      style={{ borderBottomColor: 'transparent' }}
      key={key}
      selectedKeys={[selectedKey]}
      triggerSubMenuAction="click"
    >
      {getMenuItem(menus)}
    </Menu>
  );
};
