import { createContext, FC, useContext, useMemo, useRef } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAuth } from '../auth/auth.context';
import { message } from 'antd';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

type Config = AxiosRequestConfig & { silent?: boolean | number | number[] };

export type AppAxiosInstance = Omit<
  AxiosInstance,
  'get' | 'post' | 'put' | 'patch' | 'delete'
> & {
  get<T = any, R = T>(url: string, config?: Config): Promise<R>;
  delete<T = any, R = T>(url: string, config?: Config): Promise<R>;
  options<T = any, R = T>(url: string, config?: Config): Promise<R>;
  post<T = any, R = T>(url: string, data?: any, config?: Config): Promise<R>;
  put<T = any, R = T>(url: string, data?: any, config?: Config): Promise<R>;
  patch<T = any, R = T>(url: string, data?: any, config?: Config): Promise<R>;
};

const context = createContext({} as AppAxiosInstance);

const Provider = context.Provider;

export const HttpClientProvider: FC = ({ children }) => {
  const { accessToken, logout } = useAuth();
  const { t } = useTranslation(['commons']);

  const accessTokenRef = useRef<string>();

  useEffect(() => {
    accessTokenRef.current = accessToken;
  }, [accessToken]);

  const axiosClient = useMemo(() => {
    const client = axios.create({
      baseURL: '/api',
    });
    client.interceptors.request.use((config) => {
      if (accessTokenRef.current && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${accessTokenRef.current}`;
      }
      return config;
    });
    client.interceptors.response.use(
      (response) => {
        if (
          R.any((str: string) => (response.config.url || '').startsWith(str))([
            'files/view/',
          ])
        ) {
          return response;
        }
        return response.data;
      },
      (error) => {
        const status = error.response.status;
        const msg = error.response.data?.message;
        if (status === 401) {
          logout();
          message.error(t('commons:failureOf.invalidAccessToken'));
          throw error;
        }
        if (error.response.config.silent === true) {
          throw error;
        }

        const silentCode: number[] | undefined = (() => {
          if (typeof error.response.config.silent === 'number') {
            return [error.response.config.silent];
          } else if (Array.isArray(error.response.config.silent)) {
            return error.response.config.silent;
          } else {
            return undefined;
          }
        })();

        if (silentCode?.includes(status)) {
          throw error;
        }

        if (status === 422) {
          if (Array.isArray(msg)) {
            msg.forEach((m) => {
              if (typeof m === 'string') {
                message.error(m);
              } else if (m.constraints) {
                if (m.property !== 'organizationId') {
                  message.error(Object.values(m.constraints).join(', '));
                }
              }
            });
          }
        } else if (status < 500 && status >= 400) {
          message.error(error.response.data?.message || 'Request Failed!');
        } else {
          message.error(error.response.data?.message || 'Server Down!');
        }
        throw error;
      },
    );
    return client;
  }, [logout]);

  return <Provider value={axiosClient}>{children}</Provider>;
};

export const useHttpClient = () => useContext(context);
