import { SterilizedPageMeta } from './../interfaces/page-meta.interface';
import { reduce } from 'ramda';
import { RouteConfig } from 'react-router-config';

export const generateRoutes = (pageTree: SterilizedPageMeta[]) => {
  const topLevel: RouteConfig[] = [];

  function handler(pages: SterilizedPageMeta[]): RouteConfig[] {
    return reduce(
      (prev, curr) => {
        const routes = handler(curr.children);

        if (curr.component) {
          const base: RouteConfig = {
            path: curr.fullPath,
            component: curr.component,
            exact: curr.exact,
          };

          if (curr.path.startsWith('/')) {
            topLevel.push(curr);
            return prev;
          }
          if (curr.subView !== false) {
            Object.assign(base, { routes });
            return prev.concat(base);
          }
          return prev.concat([base], routes);
        } else {
          return prev.concat(routes);
        }
      },
      [] as RouteConfig[],
      pages,
    );
  }

  return handler(pageTree).concat(topLevel);
};
