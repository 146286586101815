import { values } from 'ramda';

export enum SystemRole {
  // 系统
  system = 'system',
  // 超级用户
  super = 'super',
  // 系统管理员
  admin = 'admin',
  // 普通注册用户
  member = 'member',
}

export function isPrivilegedUser(role: SystemRole) {
  return [SystemRole.super, SystemRole.system, SystemRole.admin].includes(role);
}

export const AllOfSystemRole = values(SystemRole);
