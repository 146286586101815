import { BankOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from '../../commons/auth/auth.context';

export const SwitchOrganization: FC = () => {
  const { t } = useTranslation(['commons']);
  const history = useHistory();

  const { availableOrganizations, switchOrganization } = useAuth();
  const select = useCallback(
    (orgId: string) => {
      switchOrganization(orgId);
      history.push('/');
    },
    [switchOrganization],
  );

  const menu = (
    <Menu>
      {availableOrganizations?.map(({ id, name }) => (
        <Menu.Item key={id}>
          <a
            onClick={(e) => {
              e.preventDefault();
              select(id);
            }}
          >
            {name}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} arrow placement="bottomCenter">
      <a style={{ padding: '0 6px' }}>
        <Space>
          <BankOutlined />
          <span>{t('organization.switch')}</span>
        </Space>
      </a>
    </Dropdown>
  );
};
