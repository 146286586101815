import { DefaultHeader } from './components/default-header';
import { Layout } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { FC } from 'react';
import styles from './default.module.less';
import { useTranslation } from 'react-i18next';

const DefaultLayout: FC = ({ children }) => {
  const { t } = useTranslation(['commons']);
  return (
    <Layout>
      <DefaultHeader></DefaultHeader>
      <div style={{ overflowY: 'auto' }}>
        <Content>
          <div className={styles.content}>{children}</div>
        </Content>
        <Footer style={{ textAlign: 'center', color: '#888' }}>
          {t('system.foot')}
        </Footer>
      </div>
    </Layout>
  );
};

export default DefaultLayout;
