import { MessageOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Dropdown, List, Space } from 'antd';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from '../../commons/auth/auth.context';
import { PaginationRes } from '../../commons/models/pagination.res';
import { useHttpClient } from '../../commons/rpc/http-client.context';
import { Message } from '../../pages/personal/messages/message';

export const MessageBox: FC = () => {
  const { t } = useTranslation(['messages']);
  const history = useHistory();
  const axios = useHttpClient();

  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentOrganization } = useAuth();
  const unreadMesgContext = useMessageContext();
  const fetchData = useCallback(() => {
    setLoading(true);
    if (axios) {
      axios
        .get<PaginationRes<Message>>('/messages', {
          params: { isRead: false, pageSize: 10 },
        })
        .then((data) => setMessages(data.records))
        .finally(() => setLoading(false));
    }
  }, [axios]);
  useEffect(() => {
    fetchData();
    unreadMesgContext.refreshMes();
  }, [fetchData, currentOrganization?.id]);

  const content = (
    <Card>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={messages}
        style={{ width: '300px', maxHeight: '300px', overflow: 'auto' }}
        renderItem={(item) => (
          <List.Item style={{ padding: '8px 8px ', borderWidth: 0 }}>
            <List.Item.Meta title={item.title} description={item.content} />
          </List.Item>
        )}
      />
      <Button
        type="link"
        style={{ marginTop: 4 }}
        block
        onClick={() => history.push('/personal/messages')}
      >
        {t('box.moreUnreadMessage')}
      </Button>
    </Card>
  );

  return (
    <>
      <Dropdown
        overlay={content}
        onVisibleChange={(visible) => void (visible && fetchData())}
        arrow
        placement="bottomCenter"
      >
        <a style={{ padding: '0 6px' }}>
          <Space>
            <MessageOutlined />
            <span>{t('box.title')}</span>
          </Space>
        </a>
      </Dropdown>
      <Badge
        className="site-badge-count-109"
        count={unreadMesgContext.unreadMes.length}
        style={{ backgroundColor: '#52c41a', marginBottom: 2 }}
      />
    </>
  );
};

const context = createContext<{ refreshMes: () => void; unreadMes: Message[] }>(
  {
    refreshMes() {
      return;
    },
    unreadMes: [],
  },
);

const Provider = context.Provider;

export const useMessageContext = () => useContext(context);

export const UnreadMessagesProvider: FC = ({ children }) => {
  const axios = useHttpClient();

  const [unreadMes, setUnreadMes] = useState<Message[]>([]);
  const loadUnreadMessageCounts = async () => {
    const res = await axios.get<PaginationRes<Message>>('/messages', {
      params: { isRead: false },
    });
    setUnreadMes(res.records);
  };

  return (
    <Provider
      value={{
        refreshMes: loadUnreadMessageCounts,
        unreadMes,
      }}
    >
      {children}
    </Provider>
  );
};
