import { MenuItem } from './../interfaces/menu-item.interface';
import { last } from 'ramda';
import { SterilizedPageMeta } from './../interfaces/page-meta.interface';
export const generateMenu = (pages: SterilizedPageMeta[]) => {
  const topList = [] as MenuItem[];
  const menuMap = new Map<string, MenuItem>();

  pages.forEach((page) => {
    page.visibleInMenu && addMenu(page);
  });

  function addMenu(page: SterilizedPageMeta) {
    const menu = {
      key: page.key,
      path: page.fullPath,
      children: [],
      title: page.title,
      icon: page.icon,
    };
    menuMap.set(page.fullKey, menu);

    const parent = last(page.parents.filter((p) => p.visibleInMenu));
    if (parent) {
      let parentMenu = menuMap.get(parent.fullKey);
      if (!parentMenu) {
        parentMenu = addMenu(parent);
      }
      parentMenu.children.push(menu);
    } else {
      topList.push(menu);
    }

    return menu;
  }

  return topList;
};
