import { User } from '../../pages/system/users/models/user';
import { Organization } from './../../pages/system/organizations/models/organization';
import { AppBaseEntity } from './app-base-entity';
export class BizEntity extends AppBaseEntity {
  organization?: Organization;
  organizationId!: string;
  creatorId!: string;
  creator?: User;
  updaterId!: string;
  updater?: User;
  deletorId?: string;
  deletor?: User;
}
