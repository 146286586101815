import { last } from 'ramda';
import { useRouteConfig } from './use-route-config';
import { getPageMetaByPath } from '../utils/get-page-meta';
import { usePages } from '../contexts/pages.context';
import { useLocation } from 'react-router';
import { useMemo } from 'react';
import { matchRoutes } from 'react-router-config';

export const useMatchedPage = () => {
  const pages = usePages();
  const { pathname } = useLocation();
  const routes = useRouteConfig();
  const match = matchRoutes(routes, pathname);
  return useMemo(
    () =>
      getPageMetaByPath(
        (last(match)?.route?.path as string) ?? pathname,
        pages,
      ),
    [pages, match, pathname],
  );
};
