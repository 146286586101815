import { SterilizedPageMeta } from './../interfaces/page-meta.interface';

export const getPageMetaByPath = (
  path: string,
  pages: SterilizedPageMeta[],
) => {
  return pages.find((page) => page.fullPath === path);
};

export const getPageMetaByKey = (key: string, pages: SterilizedPageMeta[]) => {
  return pages.find((page) => page.key === key);
};
