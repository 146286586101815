import { DictionaryItem } from './dictionary-item';
import { BizEntity } from './../../../../commons/models/biz-entity';
import { DictionaryKey } from '../enums/dictionary-key.enum';
import * as R from 'ramda';
import { KeyValuePair } from 'ramda';

export class Dictionary extends BizEntity {
  key!: DictionaryKey;
  description!: string;
  items!: DictionaryItem[];
}

export class WebDictionary {
  private dictList!: Dictionary[];
  constructor(dictList: Dictionary[] = []) {
    this.dictList = dictList;
  }

  public getItems(...keys: DictionaryKey[]) {
    return R.map((key: DictionaryKey) => {
      const obj = R.find(R.propEq('key', key), this.dictList);
      return obj ? obj.items : [];
    })(keys);
  }

  public translate(
    dictKey: DictionaryKey,
    ...itemKeys: (string | undefined)[]
  ): string | null | undefined {
    if (itemKeys.length <= 0) {
      return null;
    }

    const dictionary = R.find(R.propEq('key', dictKey), this.dictList);
    if (!dictionary) {
      return null;
    }

    let items = dictionary.items;
    let target;
    for (const i of itemKeys) {
      target = R.find(R.propEq('key', i), items);
      if (!target) return itemKeys.join('.');
      items = target.children;
    }

    return R.prop('value', target as DictionaryItem) as any;
  }

  public tableValueEnum(dictKey: DictionaryKey) {
    const dictionary = R.find(R.propEq('key', dictKey), this.dictList);
    if (!dictionary) {
      return {};
    }
    return R.pipe<
      DictionaryItem[],
      KeyValuePair<string, string>[],
      Record<string, string>
    >(
      R.map((o: DictionaryItem) => [o.key, o.value]),
      R.fromPairs,
    )(dictionary.items);
  }
}
