import { createContext, FC, useContext } from 'react';
import { SterilizedPageMeta } from '../interfaces/page-meta.interface';
import { flatPageMeta } from '../utils/flat-page-meta';
import { sterilizedPageTree } from '../utils/sterilized-page-tree';

const context = createContext<SterilizedPageMeta[]>([]);

const Provider = context.Provider;

export const PagesProvider: FC = ({ children }) => {
  const pages = flatPageMeta(sterilizedPageTree);

  return <Provider value={pages}>{children}</Provider>;
};

export const usePages = () => useContext(context);
