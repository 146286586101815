import {
  BarChartOutlined,
  HomeOutlined,
  ReconciliationOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { AllOfSystemRole, SystemRole } from '../commons/auth/system-role.enum';
import { PageMeta } from './interfaces/page-meta.interface';

const pageTree: PageMeta[] = [
  {
    key: 'auth',
    visibleInMenu: false,
    systemRoles: AllOfSystemRole,
    permissions: ['dictionaries:r'],
    children: [
      {
        key: 'login',
        layout: 'auth',
        visibleInMenu: false,
        component: 'auth/login',
        permissions: ['auth:c'],
      },
    ],
  },
  {
    key: 'dashboard',
    path: '/',
    systemRoles: AllOfSystemRole,
    component: 'dashboard',
    exact: true,
    icon: <HomeOutlined />,
  },
  {
    key: 'sample-manage',
    icon: <ReconciliationOutlined />,
    children: [
      {
        key: 'sample-info',
        component: 'sample/sample',
        permissions: ['curd', 'sample:r'],
      },
      {
        key: 'sample-stock',
        component: 'sample/sample-stock',
        permissions: [
          'sample-stock-list:curd',
          'sample:curd',
          'sample-stock-detail:curd',
          'sample-storage-dev-site:r',
        ],
        exact: true,
        children: [
          {
            key: 'printed-table',
            path: ':id/printed-table',
            permissions: ['print'],
            visibleInMenu: false,
            visibleInPermission: false,
            layout: 'none',
            component: 'sample/sample-stock/printed-table',
          },
          {
            key: 'sample-stock-in',
            permissions: ['*'],
            visibleInMenu: false,
          },
          {
            key: 'sample-stock-out',
            permissions: ['*'],
            visibleInMenu: false,
          },
          {
            key: 'sample-stock-clean',
            permissions: ['*'],
            visibleInMenu: false,
          },
        ],
      },
      {
        key: 'stats-stock',
        component: 'stats/stock',
        permissions: ['curd'],
      },
    ],
  }, // sample-manage
  {
    key: 'stats',
    icon: <BarChartOutlined />,
    permissions: ['sample-type:r'],
    children: [
      {
        key: 'stats-sample',
        component: 'stats/sample',
        permissions: ['curd'],
      },
      {
        key: 'stats-sample-organization',
        systemRoles: [SystemRole.system, SystemRole.super, SystemRole.admin],
        component: 'stats/sample-organization',
        permissions: ['curd'],
      },
    ],
  }, // stats
  {
    key: 'system',
    icon: <SettingOutlined />,
    children: [
      {
        key: 'organizations',
        component: 'system/organizations',
        permissions: ['curd', 'SYS:SUPER'],
        systemRoles: [SystemRole.system, SystemRole.super, SystemRole.admin],
      },
      {
        key: 'roles',
        component: 'system/roles',
        permissions: ['curd'],
        children: [
          {
            key: 'permissions',
            component: 'system/permissions',
            path: '/system/permissions',
            permissions: ['roles:u', 'roles:r', 'binding-permissions'],
            visibleInMenu: false,
            // children: [
            //   {
            //     key: 'update-permissions',
            //     permissions: [
            //       'binding-permissions',
            //       'roles:update-permissions',
            //     ],
            //     visibleInMenu: false,
            //   },
            // ],
          },
        ],
      },
      {
        key: 'users',
        component: 'system/users',
        exact: true,
        subView: false,
        permissions: ['curd'],
        children: [
          {
            key: 'binding-roles',
            path: ':id?/binding-roles',
            component: 'system/users/binding-roles',
            permissions: ['users:u', 'roles:r'],
            visibleInMenu: false,
          },
        ],
      },
      {
        key: 'dictionaries',
        component: 'system/dictionaries',
        permissions: ['r', 'c'],
        exact: true,
        subView: false,
        children: [
          {
            key: 'modify-dictionaries',
            path: ':id',
            component: 'system/dictionaries/editor',
            visibleInMenu: false,
            permissions: ['dictionaries:u', 'dictionaries:r'],
          },
        ],
      },
      {
        key: 'sample-type',
        component: 'system/sample-type',
        permissions: ['curd'],
      },
      {
        key: 'sample-storage-device',
        component: 'system/sample-storage-device',
        permissions: ['curd', 'sample-storage-dev-site:curd'],
      },
    ],
  }, // system
  {
    key: 'personal',
    visibleInMenu: false,
    subView: false,
    systemRoles: AllOfSystemRole,
    children: [
      {
        key: 'user-info',
        component: 'personal/user-info',
        visibleInMenu: false,
        permissions: [
          'auth:r',
          'users:u',
          'users:update-base-info',
          'users:update-password',
        ],
      },
      {
        key: 'messages',
        component: 'personal/messages',
        visibleInMenu: false,
        permissions: ['messages:r', 'messages:u'],
      },
    ],
  },
];

export default pageTree;
