import { createContext, FC, useContext, useEffect, useState } from 'react';
import { NotifyDto } from './notify.dto';
import { io } from 'socket.io-client';
import { useAuth } from '../auth/auth.context';
import { NotifyLevel } from './notify-level.enum';
import { message, notification } from 'antd';

interface SubscriptionsState {
  lastNotify?: NotifyDto;
}

const Context = createContext<SubscriptionsState>({});

export const NotifyProvider: FC = ({ children }) => {
  const [lastNotify, setLastNotify] = useState<NotifyDto>();
  const { accessToken } = useAuth();

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const socket = io('', {
      path: '/api/socket.io',
      query: { accessToken },
      transports: ['websocket'],
    });
    // socket.emit('request-test-notify'); /* 要求幅度端发送测试推送消息 */

    // socket.on('connection', () => {
    //   socket.emit('request-test-notify');
    // });

    socket.on('notify', (notify: NotifyDto) => {
      notify.notifiedAt = new Date(notify.notifiedAt);
      setLastNotify(notify);
    });

    socket.on('connect', () => {
      socket.emit('subscribe-notify');
    });

    return () => {
      socket.close();
    };
  }, [accessToken]);

  useEffect(() => {
    switch (lastNotify?.level) {
      case NotifyLevel.silent:
        break;
      case NotifyLevel.message:
        message.open({
          content: lastNotify.title,
          type: lastNotify.type || 'info',
          duration: 3,
        });
        break;
      case NotifyLevel.notification:
        notification.open({
          message: lastNotify.title,
          type: lastNotify.type || 'info',
          description: lastNotify.content,
        });
    }
  }, [lastNotify]);

  return (
    <Context.Provider
      value={{
        lastNotify,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useNotify = () => {
  return useContext(Context);
};
