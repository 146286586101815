// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".default-header_header__16j6O {\n  background-color: #fff;\n  display: flex;\n  min-height: 48px;\n  align-items: center;\n  padding: 0 26px;\n  border-bottom: 1px solid #f0f0f0;\n}\n.default-header_right__3vzWm {\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://src/layouts/components/default-header.module.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;AACF;AAEA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;AAAF","sourcesContent":[".header {\n  background-color: #fff;\n  display: flex;\n  min-height: 48px;\n  align-items: center;\n  padding: 0 26px;\n  border-bottom: 1px solid #f0f0f0;\n}\n\n.right {\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n}\n\n@primary-color: #1DA57A;@layout-header-background: white;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "default-header_header__16j6O",
	"right": "default-header_right__3vzWm"
};
export default ___CSS_LOADER_EXPORT___;
