import './App.less';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from './layouts';
import { AuthProvider, useAuth } from './commons/auth/auth.context';
import { PagesProvider } from './routes/contexts/pages.context';
import { AvailablePagesProvider } from './routes/contexts/available-pages.context';
import { HttpClientProvider } from './commons/rpc/http-client.context';
import { RouterView } from './routes/components/router-view';
import DictionaryProvider from './pages/system/dictionaries/context/dictionaries.context';
import {
  NotifyProvider,
  useNotify,
} from './commons/subscriptions/notify.context';
import { FC, Suspense, useEffect } from 'react';
import { UnreadMessagesProvider } from './layouts/components/message-box';

function App(): JSX.Element {
  return (
    <div className="App">
      <Suspense fallback={null}>
        <PagesProvider>
          <BrowserRouter>
            <AuthProvider>
              <HttpClientProvider>
                <NotifyProvider>
                  <RefreshUserInfoWhenOrganizationChanged>
                    <DictionaryProvider>
                      <AvailablePagesProvider>
                        <UnreadMessagesProvider>
                          <Layout>
                            <RouterView />
                          </Layout>
                        </UnreadMessagesProvider>
                      </AvailablePagesProvider>
                    </DictionaryProvider>
                  </RefreshUserInfoWhenOrganizationChanged>
                </NotifyProvider>
              </HttpClientProvider>
            </AuthProvider>
          </BrowserRouter>
        </PagesProvider>
      </Suspense>
    </div>
  );
}

const RefreshUserInfoWhenOrganizationChanged: FC = ({ children }) => {
  const { lastNotify } = useNotify();
  const { refreshUserInfo } = useAuth();
  useEffect(() => {
    if (lastNotify?.subject === 'update-organization') {
      refreshUserInfo();
    }
  }, [lastNotify]);
  return <>{children}</>;
};

export default App;
