import { PageContainer } from '@ant-design/pro-layout';
import { Card, Skeleton } from 'antd';

const LoadingContainer = () => {
  return (
    <PageContainer
      header={{
        title: <Skeleton.Input style={{ width: 200 }} active />,
      }}
    >
      <Card>
        <Skeleton loading={true} paragraph={{ rows: 6 }} active />
      </Card>
    </PageContainer>
  );
};

export default LoadingContainer;
