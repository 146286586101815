import { createContext, FC, useContext, useMemo } from 'react';
import { useAuth } from '../../commons/auth/auth.context';
import { SterilizedPageMeta } from '../interfaces/page-meta.interface';
import { filterAvailablePageMetaByKeys } from '../utils/flat-page-meta';
import { usePages } from './pages.context';

const context = createContext<SterilizedPageMeta[]>([]);

const Provider = context.Provider;

export const AvailablePagesProvider: FC = ({ children }) => {
  const { availablePages: keys } = useAuth();

  const pages = usePages();
  const availablePages = useMemo(
    () => filterAvailablePageMetaByKeys(keys || [], pages),
    [pages, keys],
  );

  return <Provider value={availablePages}>{children}</Provider>;
};

export const useAvailablePages = () => useContext(context);
