import { isEmpty, reduce } from 'ramda';
import { lazy } from 'react';
import {
  PageMeta,
  SterilizedPageMeta,
} from '../interfaces/page-meta.interface';
import pageTree from '../routes';

export const sterilizedPageTree = sterilizePageTree(pageTree);

export function sterilizePageTree(
  pages?: PageMeta[],
  parent?: SterilizedPageMeta,
): SterilizedPageMeta[] {
  if (!pages) {
    return [];
  }
  return reduce(
    (prev, curr) => {
      const component = curr.component
        ? lazy(() => import(`../../pages/${curr.component}`))
        : undefined;

      const sourcePath = curr.path ?? curr.key;
      const fullPath = sourcePath.startsWith('/')
        ? sourcePath
        : `${parent?.fullPath ?? ''}/${sourcePath}`;

      const fullKey = parent ? `${parent.fullKey}.${curr.key}` : curr.key;

      const parents = parent ? [...parent.parents, parent] : [];

      const page = {
        ...curr,
        parents,
        component,
        fullPath,
        fullKey,
        children: [] as SterilizedPageMeta[],
      };
      if (page.path === undefined) {
        page.path = page.key;
      }
      if (page.title === undefined) {
        page.title = page.key;
      }
      if (page.exact === undefined) {
        page.exact = false;
      }
      if (page.layout === undefined) {
        page.layout = parent?.layout;
      }
      if (page.subView === undefined) {
        page.subView = false;
      }
      if (page.permissions === undefined) {
        page.permissions = [];
      } else {
        page.permissions = page.permissions.flatMap((p) => {
          if (isEmpty(p)) {
            return [];
          }
          if (p.split(':').length === 1) {
            p = `${page.key}:${p}`;
          }
          // 拼写习惯兼容
          const matched = /^([\w\d-]+):(?:(?:curd)|(?:crud))$/.exec(p);
          if (matched) {
            return [
              `${matched[1]}:c`,
              `${matched[1]}:u`,
              `${matched[1]}:r`,
              `${matched[1]}:d`,
            ];
          }
          return [p];
        });
      }
      page.permissions.push(...(parent?.permissions?.slice() ?? []));
      if (page.visibleInMenu === undefined) {
        page.visibleInMenu = true;
      }
      if (page.visibleInPermission === undefined) {
        page.visibleInPermission = true;
      }
      if (!Array.isArray(page.children)) {
        page.children = [];
      }
      if (Array.isArray(parent?.systemRoles)) {
        page.systemRoles = parent?.systemRoles;
      }
      const children = sterilizePageTree(
        curr.children,
        page as SterilizedPageMeta,
      );
      page.children = children;

      return prev.concat([page as SterilizedPageMeta]);
    },
    [] as SterilizedPageMeta[],
    pages,
  );
}
