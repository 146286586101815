// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".default_app__lMcZf {\n  height: 100%;\n  width: 100%;\n}\n.default_logo__3MjsI {\n  height: 80px;\n}\n.default_content__zTnVk {\n  min-height: calc(100vh - 48px - 70px);\n}\n.default_editable__2wvqD {\n  border: 1px solid transparent;\n  transition: background-color 0.3s linear;\n  width: 100%;\n  border-color: #eee;\n}\n.default_editable__2wvqD:hover {\n  cursor: pointer;\n  background-color: rgba(250, 250, 250);\n}\n", "",{"version":3,"sources":["webpack://src/layouts/default.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;AAEA;EACE,YAAA;AAAF;AAGA;EACE,qCAAA;AADF;AAIA;EACE,6BAAA;EACA,wCAAA;EACA,WAAA;EACA,kBAAA;AAFF;AAGE;EACE,eAAA;EACA,qCAAA;AADJ","sourcesContent":[".app {\n  height: 100%;\n  width: 100%;\n}\n\n.logo {\n  height: 80px;\n}\n\n.content {\n  min-height: calc(100vh - 48px - 70px);\n}\n\n.editable {\n  border: 1px solid transparent;\n  transition: background-color 0.3s linear;\n  width: 100%;\n  border-color: #eee;\n  &:hover {\n    cursor: pointer;\n    background-color: rgba(250, 250, 250);\n  }\n}\n\n@primary-color: #1DA57A;@layout-header-background: white;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "default_app__lMcZf",
	"logo": "default_logo__3MjsI",
	"content": "default_content__zTnVk",
	"editable": "default_editable__2wvqD"
};
export default ___CSS_LOADER_EXPORT___;
