import { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import { useAuth } from '../../commons/auth/auth.context';
import LoadingContainer from '../../layouts/container.loading';
import { useRouteConfig } from '../hooks/use-route-config';

export const RouterView = () => {
  const routes = useRouteConfig();
  const { currentOrganization } = useAuth() || {};
  return (
    <Suspense
      fallback={<LoadingContainer />}
      key={currentOrganization?.id ?? 'nil-organization'}
    >
      {renderRoutes(routes)}
    </Suspense>
  );
};
