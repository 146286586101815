import axios from 'axios';
import { LazyExoticComponent, useMemo } from 'react';
import {
  createContext,
  FC,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { LoggedRes } from './logged.res';
import ms from 'ms';

interface AuthState extends Partial<LoggedRes> {
  logout(): void;
  setLoggedUser(user: LoggedRes): void;
  switchOrganization(id: string): void;
  refreshUserInfo(): void;
  hasPermissions(permissions: string[] | string): boolean;
}
const context = createContext<AuthState>({} as AuthState);
const Provider = context.Provider;

export const AuthProvider: FC = ({ children }) => {
  const [loggedDto, setLoggedDto] = useState<LoggedRes | undefined>();
  const history = useHistory();

  const switchOrganization = useCallback(async (organizationId?: string) => {
    if (organizationId) {
      localStorage.setItem('organizationId', organizationId);
    } else if (localStorage.getItem('organizationId')) {
      organizationId = localStorage.getItem('organizationId')!;
    }

    const refreshToken = localStorage.getItem('refreshToken');
    await axios
      .get<LoggedRes>('/api/auth/token', {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
        params: {
          organizationId,
        },
      })
      .then(({ data }) => {
        authContext.setLoggedUser(data);
      })
      .catch((error) => {
        console.error(error);
        if ([401, 403].includes(error.response?.status)) {
          authContext.logout();
        }
      });
  }, []);

  const hasPermissions = useCallback(
    (requestKeys: string[] | string) => {
      if (loggedDto?.availablePermissions?.includes('*:*')) {
        return true;
      }
      if (!Array.isArray(requestKeys)) {
        requestKeys = [requestKeys];
      }
      return requestKeys.every(
        (request) =>
          loggedDto?.availablePermissions?.includes(request) ?? false,
      );
    },
    [loggedDto],
  );

  const logout = useCallback(() => {
    setLoggedDto(undefined);
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    history.push('/auth/login', { from: history.location });
  }, [setLoggedDto, history]);

  const authContext: AuthState = {
    ...loggedDto,
    logout,
    switchOrganization,
    setLoggedUser: (dto: LoggedRes) => {
      const curOrganizationId = dto.currentOrganization?.id;
      localStorage.setItem('organizationId', curOrganizationId);
      localStorage.setItem('accessToken', dto.accessToken);
      localStorage.setItem('refreshToken', dto.refreshToken);
      localStorage.setItem(
        'availablePages',
        JSON.stringify(dto.availablePages),
      );
      setLoggedDto({ ...dto });
    },
    hasPermissions,
    refreshUserInfo: () => switchOrganization(),
  };

  const [Container, setDone] = useMemo(() => {
    let isDone = false;
    let markDone: () => void = () => (isDone = true);
    const arr: [LazyExoticComponent<FC>, () => void] = [
      lazy(() => {
        const C: FC = ({ children: c }) => <>{c}</>;
        return new Promise<{ default: FC }>((resolve) => {
          if (isDone) {
            return resolve({ default: C });
          }
          markDone = () => {
            resolve({
              default: C,
            });
          };
        });
      }),
      () => markDone(),
    ];

    return arr;
  }, []);

  useEffect(() => {
    let lastClickAt = Date.now();
    const updateLastClickTime = () => {
      lastClickAt = Date.now();
    };
    window.addEventListener('click', updateLastClickTime, { capture: true });
    const id = setInterval(() => {
      if (!localStorage.getItem('refreshToken')) {
        return;
      }
      if (
        Date.now() - lastClickAt >
        ms((process.env.REACT_APP_NO_OPERATIONAL_LOGOUT_TIMEOUT as any) || '1s')
      ) {
        logout();
        return;
      }
      switchOrganization().finally();
    }, 29 * 60 * 1000);

    return () => {
      clearInterval(id);
      window.removeEventListener('click', updateLastClickTime);
    };
  }, [switchOrganization]);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      switchOrganization().finally(() => {
        setDone?.();
      });
    } else {
      logout();
      setDone?.();
    }
  }, [switchOrganization, logout]);

  return (
    <Provider value={authContext}>
      <Container>{children}</Container>
    </Provider>
  );
};

export const useAuth = () => useContext(context);
