import { SystemRole } from './../../commons/auth/system-role.enum';
import { SterilizedPageMeta } from './../interfaces/page-meta.interface';
import { FlatPageMeta } from '../interfaces/page-meta.interface';

export const flatPageMeta = (pages: SterilizedPageMeta[]) => {
  const flatPages = flatten(pages);
  return flatPages;
};

export const filterAvailablePageMeta = (
  permissions: string[],
  pages: SterilizedPageMeta[],
) => {
  if (permissions.includes('*')) {
    return pages;
  }
  return pages.filter(
    (page) =>
      page.permissions?.every((permission) =>
        permissions.includes(permission),
      ) ?? true,
  );
};

export const filterAvailablePageMetaByKeys = (
  fullKeys: string[],
  pages: SterilizedPageMeta[],
  role?: SystemRole,
) => {
  if (fullKeys.includes('*')) {
    return pages;
  }
  const availablePages = pages.filter((page) => {
    return (
      fullKeys.includes(page.fullKey) ||
      (role && page.systemRoles?.includes(role))
    );
  });

  return availablePages;
};

const flatten = (pages?: SterilizedPageMeta[]): SterilizedPageMeta[] => {
  if (!pages?.length) {
    return [];
  }
  return pages.flatMap((page) => {
    return [page, ...flatten(page.children)];
  });
};

export const normalizePageMeta = (page: FlatPageMeta) => {
  if (page.path === undefined) {
    page.path = page.key;
  }
  if (page.title === undefined) {
    page.title = page.key;
  }
  if (page.exact === undefined) {
    page.exact = false;
  }
  if (page.visibleInMenu === undefined) {
    page.visibleInMenu = true;
  }
  if (page.parents === undefined) {
    page.parents = [];
  }
  return page as Required<
    Pick<FlatPageMeta, 'path' | 'title' | 'exact' | 'visibleInMenu' | 'parents'>
  > &
    FlatPageMeta;
};
