import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth } from '../../../../commons/auth/auth.context';
import { useHttpClient } from '../../../../commons/rpc/http-client.context';
import { useNotify } from '../../../../commons/subscriptions/notify.context';
import { WebDictionary } from '../models/dictionary';

const DICT_CONTEXT = createContext<{
  dict: WebDictionary;
  reloadDict?: () => void;
}>({ dict: new WebDictionary() });

const DictionaryProvider = (props: { children: ReactNode }) => {
  // function: 加载字典
  const [webDict, setWebDict] = useState<WebDictionary>(new WebDictionary());
  const axios = useHttpClient();
  const loadDict = async () => {
    const res = await axios.get('/dictionaries', {
      params: {
        pageSize: -1,
      },
    });
    setWebDict(new WebDictionary(res?.records || []));
  };

  // socket通知，重新加载字典
  const { lastNotify } = useNotify();
  useEffect(() => {
    if (lastNotify?.subject === 'update-dictionary') {
      setTimeout(() => {
        loadDict();
      }, Math.random() * 4_000); // 0 ~ 4s 随机避让
    }
  }, [lastNotify]);

  // 切换机构，重新加载字典
  const { currentOrganization } = useAuth();
  useEffect(() => {
    if (!currentOrganization?.id) {
      return;
    }
    loadDict();
  }, [currentOrganization?.id]);

  return (
    <DICT_CONTEXT.Provider
      value={{
        dict: webDict,
        reloadDict: loadDict,
      }}
    >
      {props.children}
    </DICT_CONTEXT.Provider>
  );
};

export default DictionaryProvider;
export const useDict = () => useContext(DICT_CONTEXT);
