import { FC, lazy, Suspense, useMemo } from 'react';
import { RouterView } from '../routes/components/router-view';
import { useMatchedPage } from '../routes/hooks/use-matched-page';
import DefaultLayout from './default.layout';

export const Layout: FC = ({ children }) => {
  const matchedPage = useMatchedPage();
  const layout = matchedPage?.layout;
  const view = useMemo(() => <RouterView />, []);
  const UsedLayout = useMemo(
    () =>
      layout
        ? lazy(() =>
            import(`./${layout}.layout`).catch(() => {
              console.error(`${layout} Layout Not Found`);
              return <DefaultLayout>{view}</DefaultLayout>;
            }),
          )
        : DefaultLayout,
    [layout],
  );
  return (
    <Suspense fallback={null}>
      <UsedLayout>{children}</UsedLayout>
    </Suspense>
  );
};
